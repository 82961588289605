<!--
 * @Author: zll zll1217168026@163.com
 * @Date: 2022-07-28 10:55:53
 * @LastEditors: 墨凡 oncwnuN3VU3V55c1lqO_6llf5DtU@git.weixin.qq.com
 * @LastEditTime: 2022-09-14 14:56:04
 * @FilePath: \ding-lims\src\views\Home.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="login">
    <van-loading class="loading" color="#3d7cf3" vertical size="64">加载中...</van-loading>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions } from 'vuex'
import { Loading } from 'vant'
import { Notify } from 'vant'
Vue.use(Loading)

export default {
  name: 'Login',
  components: {
  },
  data() {
    return { }
  },
  // watch: {
  //   '$route'(to, from) {
  //     // dd.ready(() => {
  //     //   dd.device.notification.alert({
  //     //     message: from.fullPath,
  //     //     title: "from path",
  //     //     buttonName: "收到"
  //     //   })
  //     // })
  //     // if (!to.path.includes('/login')) {
  //     //   localStorage.setItem('curl', from.fullPath)
  //     // }
  //   }
  // },
  created() {
    const that = this
    this.asyncUserLogin().then(res => {
      const url = this.$route.query.redirect
      that.$router.push({path: url})
      // const curl = localStorage.getItem('curl')
      // dd.ready(() => {
      //   dd.device.notification.alert({
      //     message: curl,
      //     title: "curl path",
      //     buttonName: "收到"
      //   })
      //  })
      // if (this.$common.isEmpty(curl) || curl === '/login') {
      //   that.$router.push({path: '/home'})
      // } else {
      //   that.$router.push(curl)
      // }
    }).catch(err => {
      Notify({ type: 'danger', message: err.message, duration: 10000 })
    })
  },
  methods: {
    ...mapActions('router', ['asyncMenuData', 'asyncUserLogin']),
  }
}
</script>

<style>
  .login{
    width: 100%;
  }
  .loading{
    padding: 50% 0;
    margin: 0 auto;
    position: relative;
    top: -32px;
    text-align: center;
  }
</style>
